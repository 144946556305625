.wpwl-container {
    width: 746px;
    height: 396px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 8px;
    border: 1px solid #F4F4F4;
    box-sizing: border-box;
}

@media screen and (max-width: 1280px) {
    .wpwl-container {
        height: auto;
    }
}

@media screen and (max-width: 600px) {
    .wpwl-container {
        width: 100%;
        height: auto;
    }
}

@media screen and (max-width: 325px) {
    .wpwl-container {
        width: auto;
        height: auto;
    }
}

.wpwl-form {
    display: flex;
    flex-flow: row wrap;
    direction: ltr;
    gap: 16px;
    width: 100%;
    margin-top: 8px;
    box-sizing: border-box;
    border-radius: none;
    max-width: none;
}

.wpwl-form-card {
    background-image: none;
    background-color: transparent;
    border: none;
    box-shadow: none;
    width: 100%;
    min-width: none;
    min-height: none;
    border-radius: 0;
    height: 100%;
}

/* Input Groups */
.wpwl-group {
    width: auto;
    float: none;
    height: auto;
    margin-bottom: 8px;
}

@media (min-width: 480px) {
    .wpwl-group-cardNumber, .wpwl-group-cardHolder, .wpwl-group-birthDate {
        /* padding-right: 24px;
        width: 66.66666667%; */
        padding: 0;
    }
}

/* Payment Card Select */
.wpwl-wrapper-brand {
    width: 100%;
}

.wpwl-control-brand {
    width: 400px;
    padding: 1rem;
}

/* Payment Brands Container */
.wpwl-group-brand {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 600px) {
    .wpwl-group-brand, .wpwl-control {
        height: 40px;
    }
}

@media screen and (max-width: 400px) {
    .wpwl-wrapper-brand { 
        width: auto;
    }
}


/* For Input lables */
.wpwl-label {
    display: none;
}

/* For Lable of CVV */
.wpwl-label-cvv {
    display: none !important;
}

.wpwl-wrapper-expiry{
    width: auto;
}

/* Input Containers */
.wpwl-control {
    height: 64px;
    border-radius: 4px;
    width: auto;
    border: 1px solid #f6f6f6;
    padding: 12px;
}

@media screen and (max-width: 400px) {
    .wpwl-container {
        width: 100%;
    }
}

.wpwl-group-cardNumber {
    width: 100%;
}

.wpwl-group-cardNumber, .wpwl-group-cardHolder, .wpwl-group-expiry, .wpwl-group-cvv {
    height: 60px;
}

/* CardNumber Container */
.wpwl-wrapper-cardNumber {
    width: 100%;
    float: none;
    padding: 0;
}

/* CardNumber Input */
.wpwl-control-cardNumber {
    width: 100%;
}

/* Expiry CardHolder Input */
.wpwl-control-expiry, .wpwl-control-cardHolder{
    width: 200px;
}

@media screen and (max-width: 600px) {
    .wpwl-control-expiry, .wpwl-control-cardHolder {
        width: auto;
    }

    .wpwl-control-cvv{
        width: 100%;
    }
}

/* CVV Input */
.wpwl-control-cvv {
    width: 288px;
}


/* Pay Button Container */
.wpwl-group-submit {
    width: 100%;
}

/* Pay Button */
.wpwl-button-pay {
    width: 100%;
    height: 56px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    color: white;
    font-family: "Almarai";
    border: none;
    outline: none;
    box-shadow: none;
    background-color: #4D2C77;
    box-sizing: border-box;
}

.wpwl-button-pay:hover{
    background-color: #CA56A1;
}






 .title{
    width: 714px;
    height: 30px;
    padding: 10px;
    border-left: 2px solid #CA56A1; /* Change border-left to border-right */
    text-align: left; /* Align text to the left by default */
    font-size: 20px;
    font-weight: bold;
    color: #252525;
    font-family: "Almarai";
    box-sizing: border-box;
}

.title.arabic {
    width: 714px;
    height: 30px;
    padding: 10px;
    border-right: 2px solid #CA56A1;
    border-left:none; /* Change border-left to border-right */
    text-align: right; /* Align text to the left by default */
    font-size: 20px;
    font-weight: 700;
    color: #252525;
    font-family: "Almarai";
    margin: 0px 0px 0px 10px;
}

/* p {
    font-size: 20px;
    font-weight: 700;
    color: #252525;
    font-family: "Almarai";
    padding: 0;
    margin: 0px 0px 0px 10px;
} */

.empty {
    border-bottom: 1px solid #f4f4f4;
    width: 100%;
    margin-bottom: 20px;
}

.radio-tile-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;
}

.input-container {
    position: relative;
    opacity: 0px;
    box-sizing: border-box;
}

.input-container input {
    position: absolute;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
    z-index: 2;
    opacity: 0;
}

.input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 166px;
    height: 60px;
    border-radius: 6px;
    border: 1px solid #f6f6f6;
    transition: all 300ms ease;
    box-sizing: border-box;
}

input:checked+.radio-tile {
    border-color: #9747FF;
}

/* .wrapper {
    display: flex;
    flex-direction: row;
    gap: 16px;
    width: 100%;
    box-sizing: border-box;
}

.wpwl-control {
    height: 64px;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    padding: 0 1rem 0 1rem;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    color: #252525;
}
.wpwl-control.arabic{
    width: 100%;
    height: 64px;
    border: 1px solid #f6f6f6;
    border-radius: 4px;
    padding: 0 1rem 0 8px;
    text-align: right;
    box-sizing: border-box;
} */


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::placeholder {
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    font-family: "Almarai"; /* Align placeholder text to left by default */
}
.wpwl-control.arabic::placeholder{
    font-size: 16px;
    font-weight: normal;
    text-align: right;
    font-family: "Almarai";
}

