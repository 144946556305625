CA56A1body {
  font-family : 'Roboto', sans-serif;
}

.payment-loader {
  width : 150px;
  position: absolute;
  top: 50%;
  left : 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -moz-transform: translateY(-50%) translateX(-50%);
  -o-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.payment-loader .binding {
  content : '';
  width : 60px;
  height : 4px;
  border : 2px solid #CA56A1;
  margin : 0 auto;
}

.payment-loader .pad {
  width : 60px;
  height : 38px;
  border-radius : 8px;
  border : 2px solid #CA56A1;
  padding : 6px;
  margin : 0 auto;
}

.payment-loader .chip {
  width : 12px;
  height: 8px;
  background: #CA56A1;
  border-radius: 3px;
  margin-top: 4px;
  margin-left: 3px;
}

.payment-loader .line {
  width : 52px;
  margin-top : 6px;
  margin-left : 3px;
  height : 4px;
  background: #CA56A1;
  border-radius: 100px;
  opacity : 0;
  -webkit-animation : writeline 3s infinite ease-in;
  -moz-animation : writeline 3s infinite ease-in;
  -o-animation : writeline 3s infinite ease-in;
  animation : writeline 3s infinite ease-in;
}

.payment-loader .line2 {
  width : 32px;
  margin-top : 6px;
  margin-left : 3px;
  height : 4px;
  background: #CA56A1;
  border-radius: 100px;
  opacity : 0;
  -webkit-animation : writeline2 3s infinite ease-in;
  -moz-animation : writeline2 3s infinite ease-in;
  -o-animation : writeline2 3s infinite ease-in;
  animation : writeline2 3s infinite ease-in;
}

.payment-loader .line:first-child {
  margin-top : 0;
}

.payment-loader .line.line1 {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s;
}

.payment-loader .line.line2 {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

.payment-loader .loader-text {
  text-align : center;
  margin-top : 20px;
  font-size : 16px;
  line-height: 16px;
  color : #5f6571;
  font-weight: bold;
}


@keyframes writeline {
  0% { width : 0px; opacity: 0; }
  33% { width : 52px; opacity : 1; }
  70% { opacity : 1; }
  100% {opacity : 0; }
}

@keyframes writeline2 {
  0% { width : 0px; opacity: 0; }
  33% { width : 32px; opacity : 1; }
  70% { opacity : 1; }
  100% {opacity : 0; }
}



/* Success CSS */
._failed{ border-bottom: solid 4px red !important; }
._failed i{  color:red !important;  }

._success {
    box-shadow: 0 15px 25px #00000019;
    padding: 45px;
    width: 28rem;
    height: 22rem;
    text-align: center;
    margin: 40px auto;
    border-bottom: solid 4px #CA56A1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

._success h2 {
    margin-bottom: 12px;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 10px;
}

._success p {
    margin-bottom: 0px;
    font-size: 18px;
    color: #495057;
    font-weight: 500;
}

.go-home {
  width: auto;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem 0 1rem;
  border-radius: 4px;
  gap: 8px;
  color: white;
  font-weight: 800;
  cursor: pointer;
}